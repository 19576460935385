* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'AvenirNext';
    src: url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Regular/AvenirNextCyr-Regular.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Regular/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Regular/AvenirNextCyr-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Regular/AvenirNextCyr-Regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Regular/AvenirNextCyr-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Regular/AvenirNextCyr-Regular.svg#AvenirNext') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'AvenirNextMedium';
    src: url('./assets//fonts/AvenirNextCyr-/AvenirNextCyr-Medium/AvenirNextCyr-Medium.eot'); /* IE9 Compat Modes */
    src: url('./assets//fonts/AvenirNextCyr-/AvenirNextCyr-Medium/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Medium/AvenirNextCyr-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Medium/AvenirNextCyr-Medium.svg#AvenirNextMedium') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'AvenirNextBold';
    src: url('./assets//fonts/AvenirNextCyr-/AvenirNextCyr-Bold/AvenirNextCyr-Bold.eot'); /* IE9 Compat Modes */
    src: url('./assets//fonts/AvenirNextCyr-/AvenirNextCyr-Bold/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Bold/AvenirNextCyr-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Bold/AvenirNextCyr-Bold.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Bold/AvenirNextCyr-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/AvenirNextCyr-/AvenirNextCyr-Bold/AvenirNextCyr-Bold.svg#AvenirNextBold') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Metropolis';
    src: url('./assets/fonts/metropolis-font/Metropolis-Regular/Metropolis-Regular.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/metropolis-font/Metropolis-Regular/Metropolis-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/metropolis-font/Metropolis-Regular/Metropolis-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/metropolis-font/Metropolis-Regular/Metropolis-Regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/metropolis-font/Metropolis-Regular/Metropolis-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/metropolis-font/Metropolis-Regular/Metropolis-Regular.svg#Metropolis') format('svg'); /* Legacy iOS */
}

body {
    font-family: "AvenirNextMedium", sans-serif;
    background-color: #F8F9FA;
}